import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Spin, theme } from 'antd';
import style from './style';
import { hasPermission, paths } from '@presentation/modules/route/helpers';
import authentication from '@presentation/modules/route/authentication';
import { useSelectedMenuContext } from '@/@presentation/contexts/selectedMenu';

interface IMenu {
    isSmall: boolean;
    collapsed: boolean;
}

const Menu: React.FC<IMenu> = (props: IMenu) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const navigate = useNavigate();
    const [items, setItems] = React.useState<any>([]);

    const {
        token: { colorPrimary, colorText },
    } = theme.useToken();

    const { selectedMenu, setSelectedMenu } = useSelectedMenuContext();

    const checkPermission = async (path: any[]) => {
        const permittedItems = [];
        for (const item of path) {
            const permission = item.permission;
            if (permission) {
                const isPermitted = await hasPermission(permission);
                if (isPermitted) {
                    permittedItems.push(item);
                }
            }
        }
        setItems(permittedItems);
        setIsLoading(false);
    };

    const initialized = async () => {
        setIsLoading(true);
        setItems([]);
        await checkPermission(paths(authentication?.paths));
        setSelectedMenu(window.location.pathname);
    };

    React.useEffect(() => {
        if (items.length === 0) {
            initialized();
        }
    }, []);

    const handleNavigate = (key: string) => {
        setSelectedMenu(key);
        navigate(key);
    };

    function modifySvgFilename(filename: string, suffix: string) {
        if (filename.endsWith('.svg')) {
            const lastIndex = filename.lastIndexOf('.svg');
            return filename.substring(0, lastIndex) + suffix + filename.substring(lastIndex);
        }

        return filename;
    }

    const RenderMenu = React.useMemo(() => {
        if (isLoading) {
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50vh',
                }}>
                    <Spin size='large' />
                </div>
            );
        }
        return (
            <>
                {items?.map((item: any) => {
                    const isSelected = item.key === selectedMenu;

                    return (
                        <span
                            key={item.key}
                            tabIndex={0}
                            style={{
                                marginLeft: '10%',
                                display: 'block',
                                opacity: 1,
                                cursor: 'pointer',
                                color: isSelected ? 'white' : colorText,
                                fontWeight: 400,
                                fontSize: 14,
                                lineHeight: 1.5,
                                backgroundColor: isSelected ? colorPrimary : 'transparent',
                                padding: 7,
                                margin: 5,
                                borderRadius: isSelected ? '10px' : '0',
                            }}
                            onClick={() => handleNavigate(item.key)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleNavigate(item.key);
                                }
                            }}
                        >
                            {item.icon && <img
                                src={isSelected ? modifySvgFilename(item.icon, '-white') : modifySvgFilename(item.icon, '-grey')}
                                style={{
                                    cursor: 'not-allowed',
                                    height: 20,
                                    width: 20,
                                    color: isSelected ? 'white' : colorText,
                                    marginRight: 10,
                                }}
                            />} {item.label}
                        </span>
                    );
                })}
            </>
        );
    }, [isLoading, items, selectedMenu, colorPrimary, colorText]);

    return (
        <div style={{ marginTop: 35 }}>
            {RenderMenu}
        </div>
    );
};

export default Menu;
